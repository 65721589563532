import { $get } from '@js/request'
import { ArbitratorSchema } from '@src/components/Schema/ArbitratorSchema'
import { ConditionSchema } from '@src/components/Schema/ConditionSchema'

export async function getCaseList (
  current: number,
  pageSize: number,
  condition: ConditionSchema[]
): Promise<{ list: ArbitratorSchema[], total: number }> {
  const res = await $get('/api/xd/arbitrator', {
    condition: JSON.stringify(condition),
    current,
    page_size: pageSize
  })
  return res.data
}
