import { $post, $get } from '@js/request'
import { FileSchema } from '@src/components/Schema/FileSchema'

// 退出登录请求
export async function logoutRequest (): Promise<void> {
  const res = await $post('/api/common/logout')
  if (res.status !== 1) {
    throw new Error(res.message)
  }
}

// 获取文件
export async function getFiles (filekey: string): Promise<FileSchema | FileSchema[]> {
  const res = await $get('/api/xd/partycommon/getfilebykey', {
    filekey
  })
  return res.data
}
