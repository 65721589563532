import React from 'react'
import { Checkbox } from 'antd'
import style from './Agree.module.less'

interface Props {
  value: boolean
  onChange: (o: boolean) => void
}

function Main ({ value, onChange }: Props): React.ReactElement {
  return (
    <div className={style.container}>
      <Checkbox checked={value} onChange={e => onChange(e.target.checked)} className={style.checkbox} />
      <span onClick={() => onChange(!value)}>
        <span>请当事人认真阅读</span>
        <a className={style.link} href='http://service.wuhuzcw.cn/doc/agreement.pdf' target='blank' onClick={e => e.stopPropagation()}>《用户服务协议》</a>
        <span>及</span>
        <a className={style.link} href='http://service.wuhuzcw.cn/doc/privacy.pdf' target='blank' onClick={e => e.stopPropagation()}>《隐私政策》</a>
      </span>
    </div>
  )
}

export default Main
