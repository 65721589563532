import { $put } from '@js/request'

// 创建签名任务
export async function createTaskRequest (id: string): Promise<{ sign_url: string, taskid: string }> {
  const res = await $put(`/api/xd/meetingParticipant/filesign/${id}`)
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}
