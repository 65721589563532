import React, { ReactElement, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Dropdown, Avatar, Menu, message } from 'antd'
import { History, Location } from 'history'
import { UserOutlined, PoweroffOutlined } from '@ant-design/icons'
import { NavLink, Switch, Route, Redirect } from 'react-router-dom'
import { BASIC_STATE } from '@redux/reducers/basic'
import { USERINFO, setToken, setUserInfo } from '@redux/actions/basic'
import { companyName } from '@js/config'
import style from './Index.module.less'
import logoImg from '@imgs/logo.png'
import NewCase from './pages/NewCase/Index'
import CaseList from './pages/CaseList/Index'
import CasePayList from './pages/CasePayList/Index'
import CaseEdit from './pages/CaseEdit/Index'
import Preview from './pages/CaseDetail/Preview'
import CaseDetail from './pages/CaseDetail/Index'
import BatchCase from './pages/BatchCase/Index'
import MeetingList from './pages/MeetingList/Index'
import MeetDetail from './pages/MeetDetail/Index'
import SignStatus from './pages/MeetDetail/SignStatus'
import ArbitratorList from './pages/ArbitratorList/Index'
import DocumentList from './pages/DocumentList/Index'
import ComputeFee from './pages/ComputeFee/Index'
import { logoutRequest, getFiles } from './ajax'
import { awaitWrap } from '@src/assets/js/tool'

interface Props {
  userInfo: USERINFO
  history: History
  location: Location
  setToken: (v: string) => void
  setUserInfo: (v: USERINFO) => void
}

async function initFileByKey (key: string, setFn: (v: string) => void): Promise<void> {
  const [e, d] = await awaitWrap(getFiles(key))
  if (e === null) {
    if (d instanceof Array) {
      if (d.length > 0) {
        setFn(d[0].url)
      }
    } else if (d !== null) {
      setFn(d.url)
    }
  }
}

// 退出登录
async function logout (history: History, setToken: (v: string) => void, setUserInfo: (v: USERINFO) => void): Promise<void> {
  const hide = message.loading('退出中', 120)
  const [e] = await awaitWrap(logoutRequest())
  hide()
  if (e !== null) {
    return undefined
  }
  setToken('')
  setUserInfo({
    app_default: '',
    desktop: '',
    employeeid: '',
    id: '',
    is_auth: -1,
    mobile: '',
    realname: '',
    role_default: ''
  })
  history.push('/login')
}

// 顶部头像菜单
function renderMenu (history: History, setToken: (v: string) => void, setUserInfo: (v: USERINFO) => void): ReactElement {
  return (
    <Menu>
      <Menu.Item key={1} icon={<PoweroffOutlined className={style.error} />} onClick={async () => { await logout(history, setToken, setUserInfo) }}>退出</Menu.Item>
    </Menu>
  )
}

function Main ({ userInfo, history, location, setToken, setUserInfo }: Props): ReactElement {
  const [url1, setUrl1] = useState<string | undefined>()
  const [url2, setUrl2] = useState<string | undefined>()
  useEffect(() => {
    initFileByKey('APPLICATION', setUrl1).then(null, null)
    initFileByKey('ARBITRATIONRULES', setUrl2).then(null, null)
  }, [])
  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style['header-left']}>
          <span className={style['header-title']}>
            <img src={logoImg} />
            <span>{companyName}</span>
          </span>
        </div>

        <div className={style['header-menu']}>
          <NavLink activeClassName={style.active} to='/admin/new-case'>立案申请</NavLink>
          <NavLink activeClassName={style.active} to='/admin/case-list'>我的案件</NavLink>
          <NavLink activeClassName={style.active} to='/admin/batch-case'>批量案件</NavLink>
          <NavLink activeClassName={style.active} isActive={(match, location) => {
            return location.pathname.indexOf('/admin/case-pay-list') === 0
          }} to='/admin/case-pay-list/case-list'>案件缴费</NavLink>
          <NavLink activeClassName={style.active} to='/admin/meeting-list'>视频庭审</NavLink>
          <NavLink activeClassName={style.active} to='/admin/arbitrator-list'>仲裁员名册</NavLink>
          <NavLink activeClassName={style.active} to='/admin/document-list'>文书下载</NavLink>
          <NavLink activeClassName={style.active} to='/admin/compute-fee'>仲裁费计算</NavLink>
          <a href={url1} target='_blank'>立案须知</a>
          <a href={url2} target='_blank'>仲裁规则</a>
        </div>

        <Dropdown overlay={renderMenu(history, setToken, setUserInfo)} trigger={['click']}>
          <span className={style['header-right']}>
            <span className={style['header-person__name']}>{userInfo.realname}</span>
            <Avatar icon={<UserOutlined />} />
          </span>
        </Dropdown>
      </div>
      <div className={style.body} id='body'>
        <Switch location={location}>
          <Route exact path='/admin/new-case' component={NewCase} />
          <Route exact path='/admin/case-list' component={CaseList} />
          <Route exact path='/admin/meeting-list' component={MeetingList} />
          <Route exact path='/admin/meeting-detail/:id' component={MeetDetail} />
          <Route exact path='/admin/meeting-detail/signature/:id/:personid/:taskid' component={SignStatus} />
          <Route path='/admin/case-edit/:caseid' component={CaseEdit} />
          <Route path='/admin/preview/:caseid' component={Preview} />
          <Route path='/admin/case-detail/:caseid' component={CaseDetail} />
          <Route exact path='/admin/case-pay-list*' component={() => <CasePayList location={location} history={history} />} />
          <Route exact path='/admin/batch-case' component={BatchCase} />
          <Route exact path='/admin/arbitrator-list' component={ArbitratorList} />
          <Route exact path='/admin/document-list' component={DocumentList} />
          <Route exact path='/admin/compute-fee' component={ComputeFee} />
          <Route exact path='/admin*' component={() => <Redirect to='/admin/case-list' />} />
        </Switch>
      </div>
    </div>
  )
}

export default connect((state: { basic: BASIC_STATE }) => {
  return {
    userInfo: state.basic.userInfo
  }
}, dispatch => {
  return {
    setToken: (v: string) => dispatch(setToken(v)),
    setUserInfo: (v: USERINFO) => dispatch(setUserInfo(v))
  }
})(Main)
