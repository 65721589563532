import classNames from 'classnames'
import { ReactElement, useState, useEffect, useRef } from 'react'
import { Input, Empty, Space, Button, message } from 'antd'
import style from './Index.module.less'
import { SearchOutlined } from '@ant-design/icons'
import jicon from '@imgs/json-icon.png'
import { awaitWrap } from '@src/assets/js/tool'
import { getTemplateRequest, postCaseRequest } from './ajax'
import { confirmAction } from '@js/tool'
import { requestOneFile } from '@js/request'
import { useHistory } from 'react-router-dom'
import { History } from 'history'

const { Search } = Input

function inputHandle (fileList: FileList | null, h: History): void {
  if (fileList === null) {
    message.warning('请选择要导入的接口文档').then(null, null)
    return undefined
  }
  const c = fileList[0]
  confirmAction('您确定要导入该批次案件吗？', () => { uploadFile(c, h).catch(e => console.error(e)) })
}

// 上传模板
async function uploadFile (f: File, h: History): Promise<void> {
  const hide = message.loading('上传中', 60)
  const [e, d] = await awaitWrap(requestOneFile(f))
  hide()
  if (e !== null) {
    message.warning(e.message).then(null, null)
    return undefined
  }
  if (d !== null) {
    await postCase(d.id, h)
  }
}

// 导入案件
async function postCase (fileid: string, h: History): Promise<void> {
  const hide = message.loading('导入案件中', 60)
  const [e] = await awaitWrap(postCaseRequest(fileid))
  hide()
  if (e === null) {
    message.success('案件导入成功', 1).then(() => {
      h.push('/admin/case-list')
    }, null)
  }
}

async function getTemplate (code: string): Promise<void> {
  if (code.length === 0) {
    message.warning('请输入案件类型编号').then(null, null)
    return undefined
  }
  const hide = message.loading('查询中')
  const [e, d] = await awaitWrap(getTemplateRequest(code))
  hide()
  if (e === null && d instanceof Array) {
    if (d.length === 0) {
      message.warning('未找到对应模板').then(null, null)
      return undefined
    } else {
      const f = d[0]
      confirmAction(`您确定要下载 ${f.filename} 吗？`, () => window.open(f.url, '_blank'))
    }
  }
}

function Main (): ReactElement {
  const h = useHistory()
  const [isFixed, setIsFixed] = useState(window.document.documentElement.offsetWidth >= 1280)
  const [fileList, setFileList] = useState<FileList | null>(null)
  const [filename, setFilename] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    function initFixed (): void {
      setIsFixed(window.document.documentElement.offsetWidth >= 1280)
    }
    window.addEventListener('resize', initFixed)
    return () => {
      window.removeEventListener('resize', initFixed)
    }
  }, [])
  useEffect(() => {
    if (fileList !== null && fileList.length > 0) {
      const f: File = fileList[0]
      setFilename(f.name)
    } else {
      setFilename('')
    }
  }, [fileList])
  return (
    <div className={style.container}>
      <div className={classNames(style['left-block'], { [style.fixed]: isFixed })}>
        <div className={style['page-title']}>批量案件</div>
      </div>
      <div className={style.body}>
        <div className={style.block}>
          <div className={style.title}>1. 申请批量案件接入</div>
          <div className={style.message}>批量案件处理主要针对业务类型高度一致，案件量较大的的同质化案件，实现系统批量提交案件的能力。需要申请机构向仲裁委进行申请，经仲裁委评估可行性后，才能进行对接和接入。</div>
        </div>
        <div className={style.block}>
          <div className={style.title}>2. 下载配置文件模板</div>
          <div className={style.message}>申请通过后的单位，可根据审核后的同质化案件类型编号，获取对应的批量上传接口模板。</div>
        </div>
        <div className={style.search}>
          <Search onSearch={code => { getTemplate(code).catch(e => console.error(e)) }} addonBefore={<SearchOutlined />} placeholder='请输入案件类型编码' enterButton='查询' />
        </div>
        <div className={style.block}>
          <input className={style['file-input']} type='file' />
          <div className={style.title}>3. 上传完善后的接口模板（也可通过系统对接实现）</div>
          <div className={style['upload-block']}>
            {
              fileList !== null && fileList.length > 0
                ? (
                <div>
                  <div className={style['icon-line']}>
                    <img src={jicon} />
                  </div>
                  <div className={style['file-name']}>{filename}</div>
                </div>
                  )
                : <Empty />
            }
            <div className={style['select-file']}>
              <Space size='middle'>
                {fileList !== null && (
                  <span className={style.btn} onClick={() => {
                    const c = inputRef.current
                    if (c !== null) {
                      c.value = ''
                      setFileList(null)
                    }
                  }}>取消选择</span>
                )}
                <label className={style['file-input-block']}>
                  <input ref={inputRef} type='file' className={style['file-input']} onChange={(e) => setFileList(e.target.files)} accept='.json' />
                  <span className={style.btn}>{fileList !== null ? '重新选择' : '选择文件'}</span>
                </label>
              </Space>
            </div>
          </div>
          <div className={style['submit-line']}>
            <Button type='primary' onClick={() => { inputHandle(fileList, h) }}>导入</Button>
          </div>
          <div className={style['notice-block']}>
            <div className={style['notice-label']}>说明1</div>
            <div className={style['notice-text']}>请将一个或多个案件数据保存在一个json文件直接上传。如果同一个案件中，出现相同姓名的当事人或代理人，请使用[数字]，如[1]，[2]，等进行区分标识。</div>
            <div className={style['notice-label']}>说明2</div>
            <div className={style['notice-text']}>为确保平台运行稳定性，单次限制至多50个案件要素表。超出部分请在本次批量立案提交后，再次进行批量立案提交操作。批量案件提交过程中，请尽可能关闭其他运行程序，并保证网络稳定。</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Main
