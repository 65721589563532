import React, { ReactElement } from 'react'
import { Anchor, Button } from 'antd'
import style from './Protocol.module.less'

const { Link } = Anchor

interface Props {
  setAgree: (v: boolean) => void
  loading: boolean
}

function Main ({ setAgree, loading }: Props): ReactElement {
  return (
    <div className={style.container}>
      <div className={style.anchors} id='protocolAnchor'>
        <Anchor offsetTop={64} targetOffset={88} className={style.menu}>
          <Link href='#网络预立案须知' title='网络预立案须知'>
            <Link href='#第一条' title='第一条' />
            <Link href='#第二条' title='第二条' />
            <Link href='#第三条' title='第三条' />
            <Link href='#第四条' title='第四条' />
          </Link>
        </Anchor>
      </div>
      <div className={style.message}>
        <h2 className={style.h2} id='网络预立案须知'>网络预立案须知</h2>
        <div id='第一条'>
          <p className={style.p}>1.申请人或代理人应当遵循诚实信用原则，如实填写预立案申请信息，对填写材料的合法性、真实性负责。</p>
        </div>
        <div id='第二条'>
          <p className={style.p}>2.立案范围：本会受理自然人、法人和非法人组织之间发生的合同争议和其他财产权益争议，包括：（1）国际或者涉外的争议案件；（2）涉及中国香港特别行政区、澳门特别行政区或者台湾地区的争议案件；（3）中国内地的争议案件。</p>
          <p className={style.p}>本会不受理下列争议：（1）婚姻、收养、监护、扶养、继承纠纷；（2）依法应当由行政机关处理的行政争议；（3）劳动争议（请注意：涉及劳动合同签订、解除、工资、复利、待遇、辞退、误工费等纠纷，均不属于本会受理范围）；（4）农业集体经济组织内部的农业承包合同纠纷。</p>
        </div>
        <div id='第三条'>
          <p className={style.p}>3.请严格按照本会<a className={style.link} href='/open/立案材料清单.pdf' download='立案材料清单.pdf'>立案材料清单</a>的要求准备好纸质版的仲裁申请材料，依照系统提示填写相关信息，填写完毕后请点击“预览提交”。</p>
        </div>
        <div id='第四条'>
          <p className={style.p}>4.网络预立案仅是对申请仲裁材料是否齐备进行审核，提交材料后不代表本会已接受仲裁申请，<span className={style.important}>请将书面材料邮寄至本会立案室</span>（地址：安徽省芜湖市鸠江区瑞祥路88号皖江财富广场C1座（市民服务中心）5楼公共法律服务中心，联系方式：0553-2963126）或至本会立案室现场提交书面材料，立案室仍需审核您提交的书面材料，请确保您在预立案系统填写的内容与提交的书面仲裁申请材料一致。  </p>
        </div>
        <Button type='primary' disabled={loading} onClick={() => setAgree(true)}>我同意遵守《芜湖仲裁委员会网络预立案须知》</Button>
      </div>
    </div>
  )
}

export default Main
