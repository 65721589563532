import { $get, $post } from '@js/request'
import { FileSchema } from '@src/components/Schema/FileSchema'

// 获取模板
export async function getTemplateRequest (code: string): Promise<FileSchema[]> {
  const res = await $get('/api/xd/caseapi/getbycode', {
    code
  })
  return res.data
}

// 导入模板
export async function postCaseRequest (fileid: string): Promise<string[]> {
  const res = await $post('/api/xd/caseapi/postCase', { fileid })
  return res.data
}
