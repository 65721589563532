import { requestOneFile, $put, $post, $delete } from '@js/request'
import { PartySchema } from '@components/Schema/PartySchema'
import { compressLicense } from '@src/assets/js/tool'

// 更新或新增当事人数据
export async function partyRequest (p: PartySchema): Promise<void> {
  if (p.front instanceof File) {
    const res = await requestOneFile(p.front)
    p.fileid = res.id
  }
  if (p.back instanceof File) {
    const res = await requestOneFile(p.back)
    p.fileid1 = res.id
  }
  compressLicense(p)
  p.tag = JSON.stringify({ subject_type_tag: p.subject_type_tag })
  const id = p.id
  delete p.id
  delete p.front
  delete p.back
  delete p.file
  delete p.file1
  let res
  if (id === undefined) {
    res = await $post('/api/xd/party', p) // 新增数据
  } else {
    res = await $put(`/api/xd/party/${id}`, p) // 更新数据
  }
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}

// 删除当事人
export async function delPartyRequest (id: string): Promise<void> {
  const res = await $delete(`/api/xd/party/${id}`)
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}
