import React, { ReactElement, useState, useEffect } from 'react'
import { ClockCircleOutlined } from '@ant-design/icons'
import style from './Index.module.less'
import { filterEmptyText, formatDate, isEmptyString } from '@js/tool'
import classNames from 'classnames'
import { MeetSchema } from '@components/Schema/MeetSchema'

interface Props {
  data: MeetSchema
}

// 格式化案件状态和样式
function formatStatus (s: string): { name: string, status: string } {
  const name = [style.status]
  if (s === '待审核') {
    name.push(style.pass)
  } else if (s === '已受理') {
    name.push(style.wait)
  } else if (['送达中', '审理中'].includes(s)) {
    name.push(style.wait)
  } else if (['已结案', '不予受理'].includes(s)) {
    name.push(style.close)
  } else if (s === '审核不通过') {
    name.push(style.unpass)
  } else {
    name.push(style.unsubmit)
  }
  return {
    status: s,
    name: name.join(' ')
  }
}

function Main ({ data }: Props): ReactElement {
  const [applicantName, setApplicantName] = useState('')
  const [respondentName, setRespondentName] = useState('')
  const [arbitrators, setArbitrators] = useState('')
  const [status, setStatus] = useState('')
  const [statusNames, setStatusNames] = useState('')
  const [isFixed, setIsFixed] = useState(window.document.documentElement.offsetWidth >= 1280)
  useEffect(() => {
    if (data !== null) {
      setApplicantName(data.applicant_name)
      setRespondentName(data.respondent_name)
      setArbitrators(data.arbitrators)
      const o = formatStatus(data.case_status)
      setStatus(o.status)
      setStatusNames(o.name)
    }
  }, [data])
  useEffect(() => {
    const fn = function (): void {
      setIsFixed(window.document.documentElement.offsetWidth >= 1280)
    }
    window.addEventListener('resize', fn)
    return () => {
      window.removeEventListener('resize', fn)
    }
  }, [])
  return (
    <div className={classNames(style.container, { [style.fixed]: isFixed })}>
      <div className={style.caseno}>案号：{filterEmptyText(data.caseno_desc)}</div>
      <div className={style.line}>
        <span className={style.label}>申请人</span>
        <span>{filterEmptyText(applicantName)}</span>
      </div>
      <div className={style.line}>
        <span className={style.label}>被申请人</span>
        <span>{filterEmptyText(respondentName)}</span>
      </div>
      <div className={style.line}>
        <span className={style.label}>受理日期</span>
        <span>{formatDate(data.accept_datetime)}</span>
      </div>
      <div className={style.line}>
        <span className={style.label}>案件状态</span>
        <span className={statusNames}>
          <ClockCircleOutlined className={style['status-icon']} />
          <span>{status}</span>
        </span>
      </div>
      <div className={style.line}>
        <span className={style.label}>经办秘书</span>
        <span>{filterEmptyText(data.processer)}{!isEmptyString(data.processer_tel) && `（${data.processer_tel}）`}</span>
      </div>
      <div className={style.line}>
        <span className={style.label}>仲裁员</span>
        <span>{filterEmptyText(arbitrators)}</span>
      </div>
    </div>
  )
}

export default Main
