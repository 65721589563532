import { $get } from '@js/request'
import { FileSchema } from '@src/components/Schema/FileSchema'

// 获取模板
export async function getTemplateRequest (): Promise<FileSchema[]> {
  const res = await $get('/api/xd/partycommon/getfilebykey', {
    filekey: 'ARBITRATIONDOC'
  })
  return res.data
}
