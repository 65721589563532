import { ReactElement, useState, useEffect } from 'react'
import { History } from 'history'
import { Skeleton, message, Space, Button, Modal, Alert } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { match } from 'react-router-dom'
import { connect } from 'react-redux'
import { USERINFO } from '@src/redux/actions/basic'
import { BASIC_STATE } from '@src/redux/reducers/basic'
import logo from '@imgs/logo.png'
import style from './Preview.module.less'
import Person from './components/Person/Index'
import Claim from './components/Claim/Index'
import Accord from './components/Accord/Index'
import Evidence from './components/Evidence/Index'
import { getCaseDetailRequest, updateCaseRequest } from './ajax'
import { awaitWrap } from '@js/tool'
import { CaseSchema } from '@src/components/Schema/CaseSchema'

const { confirm } = Modal

interface Params {
  caseid: string
}

interface Props {
  history: History
  match: match<Params>
  userInfo: USERINFO
}

// 初始化案件数据
async function initData (caseid: string, h: History, setCaseDetail: (v: CaseSchema) => void): Promise<void> {
  const hide = message.loading('加载中', 120)
  const [e, d] = await awaitWrap(getCaseDetailRequest(caseid))
  hide()
  if (e !== null) {
    return undefined
  } else if (d === null) {
    return await message.error('数据未返回')
  }
  if (!['未提交', '审核不通过', null, undefined, ''].includes(d.status)) {
    h.push('/admin/case-list')
    return await message.warning('案件非可编辑状态')
  }
  setCaseDetail(formatInitData(d))
}

// 格式化争议金额
function formatInitData (d: CaseSchema): CaseSchema {
  const { claim } = d
  const { items } = claim
  if (claim.dispute_amount === null) {
    claim.dispute_amount = 0
    claim.fee_acceptance = 0
    claim.fee_processing = 0
    items.forEach(item => {
      if (typeof item.dispute_amount === 'number') {
        claim.dispute_amount += item.dispute_amount
      }
      if (typeof item.fee_acceptance === 'number') {
        claim.fee_acceptance += item.fee_acceptance
      }
      if (typeof item.fee_processing === 'number') {
        claim.fee_processing += item.fee_processing
      }
    })
  }
  return d
}

// 确认提交
function confirmSubmit (d: CaseSchema, h: History, u: USERINFO): void {
  confirm({
    icon: <ExclamationCircleOutlined />,
    content: '您确定要提交吗？',
    onOk: () => {
      submitHandle(d, h, u).catch(e => console.error(e))
    }
  })
}

// 提交审核
async function submitHandle (d: CaseSchema, h: History, u: USERINFO): Promise<void> {
  const hide = message.loading('提交中', 120)
  const [e] = await awaitWrap(updateCaseRequest(d, { status: '待审核' }, u))
  hide()
  if (e !== null) {
    return undefined
  }
  h.push('/admin/case-list')
}

function Main ({ history, match, userInfo }: Props): ReactElement {
  const [caseDetail, setCaseDetail] = useState<CaseSchema | null>(null)
  useEffect(() => {
    if (match !== null) {
      initData(match.params.caseid, history, setCaseDetail).catch(e => console.error(e))
    }
  }, [match])
  if (caseDetail === null) {
    return (
      <div className={style.loading}><Skeleton active /></div>
    )
  }
  return (
    <div className={style.container}>
      <div className={style.body}>
        <div className={style.header}>
          <span className={style['header-title']}>
            <img className={style.logo} src={logo} />
            <span className={style['header-title-text']}>仲裁申请书</span>
          </span>
        </div>
        <Alert type='warning' showIcon message='请将书面材料邮寄至本会立案室' closable />
        <Person title='申请人' data={caseDetail.applicants} />
        <Person title='申请人代理人' data={caseDetail.applicant_agents} />
        <Person title='被申请人' data={caseDetail.respondents} isRespondent />
        <Accord data={caseDetail} />
        <Claim data={caseDetail.claim} />
        <Evidence data={caseDetail.evidence} />
        <div className={style['btn-line']}>
          <Space>
            <Button onClick={() => history.push(`/admin/case-edit/${match.params.caseid}`)}>返回编辑</Button>
            <Button type='primary' onClick={() => confirmSubmit(caseDetail, history, userInfo)}>确认提交</Button>
          </Space>
        </div>
      </div>
    </div>
  )
}

export default connect((state: { basic: BASIC_STATE }) => {
  return {
    userInfo: state.basic.userInfo
  }
}, dispatch => {
  return {

  }
})(Main)
