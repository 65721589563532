import React, { ReactElement, useRef, useState, useEffect, RefObject } from 'react'
import { History } from 'history'
import { Alert, Form, Select, Input, Space, Button, message, Modal } from 'antd'
import _ from 'lodash'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { FormInstance } from 'antd/lib/form'
import { connect } from 'react-redux'
import { match } from 'react-router-dom'
import { CaseSchema } from '@components/Schema/CaseSchema'
import style from './Index.module.less'
import backIcon from '@imgs/back-arrow.png'
import Dictionary, { DictionaryItem } from '@js/dictionary'
import { BASIC_STATE } from '@redux/reducers/basic'
import { USERINFO } from '@redux/actions/basic'
import { initIsSelf } from '../../tool'
import NameInput from './components/NameInput/Index'
import IdentityCard from '../../components/IdentityCard/Index'
import { PartySchema } from '@src/components/Schema/PartySchema'
import { partyRequest, delPartyRequest } from './ajax'
import { awaitWrap, scrollToFirstError } from '@js/tool'

const { Item } = Form
const { Option } = Select
const { TextArea } = Input
const { confirm } = Modal

interface Params {
  caseid: string
  type: string
  id?: string
}

interface Props {
  history: History
  match: match<Params>
  caseDetail: CaseSchema
  dictionary: Dictionary
  userInfo: USERINFO
  refreshCase: () => {}
}
// 改变主体类型
function changeSubjectType (t: string, formRef: RefObject<FormInstance>, items: DictionaryItem[], setIdentityType: (v: string) => void): void {
  const c = formRef.current
  if (c === null) {
    return undefined
  }
  let obj: DictionaryItem | null = null
  for (let i = 0; i < items.length; i++) {
    const li = items[i]
    if (li.title === t) {
      obj = li
      break
    }
  }
  if (obj !== null) {
    c.setFieldsValue({
      subject_type: obj.value,
      subject_type_tag: t,
      license_type: undefined
    })
    setIdentityType(obj.value)
  }
}

// 获取初始化数据
function initPartyData (id: string | undefined, caseDetail: CaseSchema, fromRef: RefObject<FormInstance>, setPartyData: (v: PartySchema | null) => void): void {
  const c = fromRef.current
  if (id === undefined || c === null) {
    return setPartyData(null)
  }
  let d: PartySchema|null = null
  const l = [...caseDetail.applicants, ...caseDetail.respondents]
  for (let i = 0; i < l.length; i++) {
    if (l[i].id === id) {
      d = l[i]
      break
    }
  }
  if (d === null) {
    return setPartyData(null)
  }
  setPartyData(d)
  d = _.cloneDeep(d)
  formatData(d)
  c.setFieldsValue(d)
}

// 格式化数据标签
function formatData (d: PartySchema): void {
  if (d.tag !== null && d.tag !== undefined) {
    try {
      const o: { subject_type_tag?: string } = JSON.parse(d.tag)
      if (o.subject_type_tag !== undefined) {
        d.subject_type_tag = o.subject_type_tag
      }
    } catch (e) {
      console.error(e)
    }
  }
  if (d.file instanceof Array && d.file.length > 0) {
    d.front = d.file[0]
  }
  if (d.file1 instanceof Array && d.file1.length > 0) {
    d.back = d.file1[0]
  }
}

// 提交
async function submitHandle (p: PartySchema, origin: PartySchema | null, history: History, params: Params, refreshCase: () => {}): Promise<void> {
  const hide = message.loading('提交中', 120)
  if (p.id !== undefined && origin !== null) {
    p = { ...origin, ...p }
  } else {
    p.caseid = params.caseid
    p.type = params.type === 'applicant' ? '申请人' : '被申请人'
  }
  const [e] = await awaitWrap(partyRequest(_.cloneDeep(p)))
  hide()
  if (e !== null) {
    await undefined
  } else {
    history.push(`/admin/case-edit/${params.caseid}`)
    refreshCase()
    await message.success(p.id !== undefined ? '更新成功' : '新增成功')
  }
}

// 删除
async function delHandle (p: PartySchema, c: CaseSchema, history: History, refreshCase: () => {}): Promise<void> {
  const hide = message.loading('删除中', 120)
  const [e] = await awaitWrap(delPartyRequest(p.id === undefined ? '' : p.id))
  hide()
  Modal.destroyAll()
  if (e !== null) {
    await undefined
  } else {
    refreshCase()
    history.push(`/admin/case-edit/${c.id}`)
    await message.success('删除成功')
  }
}

// 删除当事人
async function confrimDel (p: PartySchema, c: CaseSchema, history: History, refreshCase: () => {}): Promise<void> {
  const agents = [...c.applicant_agents, ...c.respondents_agents]
  const ids = new Set()
  agents.forEach(a => {
    a.party.forEach(l => ids.add(l.partyid))
  })
  if (ids.has(p.id)) {
    return await message.warning('该当事人存在代理关系，请先取消代理关系')
  }
  confirm({
    icon: <ExclamationCircleOutlined />,
    content: `您确定要删除 ${p.name} 吗？`,
    onOk: () => {
      delHandle(p, c, history, refreshCase).catch(e => console.error(e))
    }
  })
}

// 根据当事人类型渲染输入项
function renderBlockByIdentityType (identityType: string, areaList: DictionaryItem[], isSelf: boolean): ReactElement {
  if (identityType === '企业') {
    return (
      <Item name='legal' label='法定代表人'>
        <Input className={style.long} placeholder='请输入法定代表人' />
      </Item>
    )
  }
  return (
    <>
      <Item label='所在地' name='area' rules={[{ required: true, message: '所在地不能为空' }]}>
        <Select placeholder='请选择所在地' className={style.long}>
          {areaList.map((li, i) => (
            <Option value={li.title} key={li.title}>{li.title}</Option>
          ))}
        </Select>
      </Item>
      <Item label='证件照' style={{ marginBottom: 0 }}>
        <Space size={24}>
          <Item name='front' style={{ display: 'inline-block' }} rules={[{ required: false, message: '人相照不能为空' }]}>
            <IdentityCard label='人相照' isFront disabled={isSelf} />
          </Item>
          <Item name='back' style={{ display: 'inline-block' }} rules={[{ required: false, message: '国徽照不能为空' }]}>
            <IdentityCard label='国徽照' isFront={false} disabled={isSelf} />
          </Item>
        </Space>
      </Item>
    </>
  )
}

function Main ({ history, match, caseDetail, dictionary, userInfo, refreshCase }: Props): ReactElement {
  const formRef = useRef(null)
  const [typeLabel, setTypeLabel] = useState('申请人')
  const [subjectTypes, setSubjectTypes] = useState<DictionaryItem[]>([])
  const [identityTypeList, setIdentityTypeList] = useState<DictionaryItem[]>([])
  const [areaList, setAreaList] = useState<DictionaryItem[]>([])
  const [addressList, setAddressList] = useState<DictionaryItem[]>([])
  const [sendAddressList, setSendAddressList] = useState<DictionaryItem[]>([])
  const [isSelf, setIsSelf] = useState(false)
  const [identityType, setIdentityType] = useState('自然人')
  const [nameLabel, setNameLabel] = useState('姓名')
  const [partyData, setPartyData] = useState<PartySchema | null>(null)
  useEffect(() => {
    setSubjectTypes(dictionary.getList('主体类型'))
    setAreaList(dictionary.getList('所在地'))
    setAddressList(dictionary.getList('送达地址类型'))
    setSendAddressList(dictionary.getList('当事人选择送达地址类型'))
  }, [dictionary])
  useEffect(() => {
    if (match !== null) {
      const { id, type, caseid } = match.params
      if (!['applicant', 'respondent'].includes(type)) {
        history.push(`/admin/case-edit/${caseid}`)
      }
      setTypeLabel(type === 'applicant' ? '申请人' : '被申请人')
      initIsSelf(caseDetail, setIsSelf, userInfo.id, id)
      initPartyData(id, caseDetail, formRef, setPartyData)
    }
  }, [match])
  useEffect(() => {
    if (identityType === '企业') {
      setNameLabel('公司名称')
      setIdentityTypeList(dictionary.getList('企业证件类型'))
    } else {
      setNameLabel('姓名')
      setIdentityTypeList(dictionary.getList('自然人证件类型'))
    }
  }, [identityType])
  return (
    <div className={style.container}>
      <div className={style.header}>
        <span className={style['header-icon']} onClick={() => history.goBack()}>
          <img src={backIcon} />
        </span>
        <span className={style['header-title']}>{typeLabel}</span>
      </div>
      <Alert
        style={{ marginBottom: '20px' }}
        message={(
          <div>
            <div>1、当事人信息自动获取由第三方服务接口提供，仅供预立案参考，请核对信息无误后再提交案件。</div>
            <div>2、手机号码请填写当事人手机号，请勿填写代理人手机号。</div>
          </div>
        )}
        type='warning'
        closable
      />
      <Form ref={formRef} className={style.form} labelCol={{ flex: '138px' }} labelAlign='left' colon={false} onFinish={async d => await submitHandle(d, partyData, history, match.params, refreshCase)} scrollToFirstError={scrollToFirstError}>
        <Item name='id' noStyle><span /></Item>
        <Item name='subject_type' noStyle><span /></Item>
        <Item name='type' noStyle><span /></Item>
        <Item name='subject_type_tag' label='主体类型' rules={[{ required: true, message: '主体类型不能为空' }]}>
          <Select className={style.long} placeholder='请选择主体类型' onChange={v => changeSubjectType(v as string, formRef, subjectTypes, setIdentityType)} disabled={isSelf}>
            {subjectTypes.map(li => <Option key={li.title} value={li.title}>{li.title}</Option>)}
          </Select>
        </Item>
        <Item name='name' label={nameLabel} rules={[{ required: true, message: `${nameLabel}不能为空` }]}>
          <NameInput identityType={identityType} nameLabel={nameLabel} className={style.long} formRef={formRef} isSelf={isSelf} />
        </Item>
        <Item name='license_type' label='证件类型' rules={[{ required: true, message: '证件类型不能为空' }]}>
          <Select className={style.long} placeholder='请选择证件类型' disabled={isSelf}>
            {identityTypeList.map((li, i) => <Option key={i} value={li.title}>{li.title}</Option>)}
          </Select>
        </Item>
        <Item name='license' label='证件号码' rules={[{ required: true, message: '证件号码不能为空' }]}>
          <Input className={style.long} placeholder='请输入证件号码' disabled={isSelf} />
        </Item>
        {renderBlockByIdentityType(identityType, areaList, isSelf)}
        <Item name='address_type' label='地址类型' rules={[{ required: true, message: '地址类型不能为空' }]}>
          <Select className={style.long} placeholder='请选择地址类型'>
            {addressList.map((li, i) => <Option key={i} value={li.value}>{li.title}</Option>)}
          </Select>
        </Item>
        <Item name='address' label='地址' rules={[{ required: true, message: '地址不能为空' }]}>
          <TextArea rows={3} className={style.long} placeholder='请输入地址' />
        </Item>
        <Item name='contact' label='收件人'>
          <Input className={style.long} placeholder='请输入收件人' />
        </Item>
        <Item name='deliver_address_type' label='送达地址类型'>
          <Select className={style.long} placeholder='请选择送达地址类型'>
            {sendAddressList.map((li, i) => <Option key={i} value={li.value}>{li.title}</Option>)}
          </Select>
        </Item>
        <Item name='deliver_address' label='送达地址'>
          <TextArea rows={3} className={style.long} placeholder='请输入送达地址' />
        </Item>
        <Item name='mobile' label='手机号码'>
          <Input className={style.short} placeholder='请输入手机号码' />
        </Item>
        <Item name='email' label='邮箱'>
          <Input className={style.short} placeholder='请输入邮箱' />
        </Item>
        <Item name='tel' label='固定电话'>
          <Input className={style.short} placeholder='请输入固定电话' />
        </Item>
        <div className={style['btn-line']}>
          <Space size='middle'>
            {partyData?.id !== undefined ? <Button disabled={isSelf} onClick={async () => await confrimDel(partyData, caseDetail, history, refreshCase)}>删除</Button> : null}
            <Button type='primary' htmlType='submit'>确认</Button>
          </Space>
        </div>
      </Form>
    </div>
  )
}

export default connect((state: { basic: BASIC_STATE }) => {
  return {
    userInfo: state.basic.userInfo
  }
}, dispatch => {
  return {}
})(Main)
