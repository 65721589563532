import { $get, $post } from '@js/request'
import { AccountSchema } from '@components/Schema/AccountSchema'
import { CaseSchema } from '@src/components/Schema/CaseSchema'
import { compressLicense, decompressLicense } from '@src/assets/js/tool'

// 获取登录者信息
export async function getAccountInfoRequest (): Promise<AccountSchema> {
  const res = await $get('/api/xd/certify/getByAccountid')
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  decompressLicense(res.data)
  return res.data
}

// 创建新案件
export async function createCaseRequest (): Promise<string> {
  const res = await $post('/api/xd/case')
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}

// 新增当事人
export async function addPartyRequest (d: { [k: string]: any }): Promise<void> {
  compressLicense(d)
  const res = await $post('/api/xd/party', d)
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}

// 新增代理人
export async function addAgentRequest (d: object): Promise<void> {
  compressLicense(d)
  const res = await $post('/api/xd/agent', d)
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}

// 获取案件基本信息
export async function getCaseRequest (caseid: string): Promise<CaseSchema> {
  const res = await $get(`/api/xd/case/${caseid}`)
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}
