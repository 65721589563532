import { $get, $put, $post } from '@js/request'
import _ from 'lodash'
import { CaseSchema } from '@components/Schema/CaseSchema'
import { USERINFO } from '@src/redux/actions/basic'
import { TribunalPartySchema } from '@src/components/Schema/TribunalPartySchema'
import { TribunalSchema } from '@src/components/Schema/TribunalSchema'
import { formatCaseDataLicense } from '@src/assets/js/tool'

// 获取案件详情请求请求
export async function getCaseDetailRequest (caseid: string): Promise<CaseSchema> {
  const res = await $get(`/api/xd/case/partyCaseRead/${caseid}`)
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  formatCaseDataLicense(res.data)
  return res.data
}

export interface SubData { [v: string]: any }

// 更新案件数据
export async function updateCaseRequest (caseDetail: CaseSchema, modifyData: SubData, userInfo: USERINFO): Promise<void> {
  const d = _.cloneDeep(caseDetail)
  const id = d.id
  d.claim.items.forEach(li => {
    delete li.id
  })
  const o: SubData = {
    claim: d.claim,
    submittal: userInfo.realname,
    submittalid: userInfo.id,
    submit_datetime: Math.floor(Date.now() / 1000),
    basis: d.basis,
    cause_action: d.cause_action,
    status: d.status,
    basis_fileid: d.basis_fileid,
    ...modifyData
  }

  const res = await $put(`/api/xd/case/${id}`, o)
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}

export interface OperateItem {
  flow_name: string
  key: string
  orderno: number
  title: string
}

export interface OperateConfig {
  unuse: OperateItem[]
  used: OperateItem[]
}

// 获取操作列表
export async function getOperateRequest (caseid: string): Promise<OperateConfig> {
  const res = await $get('/api/xd/caseoperate/partyOperate', {
    caseid
  })
  return res.data
}

// 更新操作
export async function addOperateUsedRequest (caseid: string, operate: string): Promise<void> {
  const res = await $post('/api/xd/caseoperate', {
    caseid,
    operate
  })
  return res.data
}

// 获取最新组庭信息
export async function getCourtRequest (caseid: string, caseDetail: CaseSchema, uid: string): Promise<TribunalPartySchema[]> {
  const res = await $get('/api/xd/tribunal/getPartyTribunal', { caseid })
  const list = res.data
  if (list.length > 0) {
    return list
  }
  const oList = await getAllCourtRequest(caseid)
  if (oList.length === 0) {
    return []
  }
  oList.sort((a, b) => b.create_datetime - a.create_datetime)
  return formatPartyList(oList, caseDetail, uid)
}

// 获取案件全部组庭信息
export async function getAllCourtRequest (caseid: string): Promise<TribunalSchema[]> {
  const res = await $get('/api/xd/tribunal', { caseid })
  return res.data
}

function formatPartyList (list: TribunalSchema[], caseDetail: CaseSchema, uid: string): TribunalPartySchema[] {
  const o = list[0]
  const partys = [...caseDetail.applicants, ...caseDetail.respondents]
  const agents = [...caseDetail.applicant_agents, ...caseDetail.respondents_agents]
  let index = partys.map(li => li.accountid).indexOf(uid)
  let ids: Array<string|undefined> = []
  if (index !== -1) {
    ids.push(partys[index].id)
  } else {
    index = agents.map(li => li.accountid).indexOf(uid)
    if (index !== -1) {
      ids = agents[index].party.map(li => li.partyid)
    }
  }
  const result = o.tribunal_party.filter(li => ids.includes(li.partyid))
  result.forEach(li => {
    li.tribunal_arbitrator = o.arbitrator
    li.tribunal_arbitrator1 = o.arbitrator1
    li.tribunal_arbitrator2 = o.arbitrator2
    li.tribunal_arbitratorid = o.arbitratorid
    li.tribunal_arbitratorid1 = o.arbitratorid1
    li.tribunal_arbitratorid2 = o.arbitratorid2
    li.tribunal_status = o.status
    li.tribunal_assign_datetime = o.assign_datetime
  })
  return result
}
