import { $get, $put } from '@js/request'
import { MeetSchema } from '@components/Schema/MeetSchema'

// 获取详情请求
export async function getDetailRequest (id: string): Promise<MeetSchema> {
  const res = await $get(`/api/xd/meeting/${id}`)
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}

// 创建签名任务
export async function createTaskRequest (id: string): Promise<{ sign_url: string, taskid: string }> {
  const res = await $put(`/api/xd/meetingParticipant/filesign/${id}`)
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}

// 查看签署情况
export async function searchTaskStatusRequest (id: string): Promise<{ data: Object, message: string }> {
  const res = await $put(`/api/xd/meetingParticipant/filesignto/${id}`)
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res
}
