import React, { ReactElement, useState, useEffect } from 'react'
import { match } from 'react-router-dom'
import { History, Location } from 'history'
import { Skeleton, BackTop, message, Button, Result } from 'antd'
import { ToTopOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { USERINFO } from '@src/redux/actions/basic'
import { BASIC_STATE } from '@src/redux/reducers/basic'
import style from './Index.module.less'
import BerifInfo from './components/BerifInfo/Index'
import logo from '@imgs/logo.png'
import { MeetSchema } from '@components/Schema/MeetSchema'
import { getDetailRequest, searchTaskStatusRequest, createTaskRequest } from './ajax'
import { awaitWrap, resetUrlParams } from '@js/tool'
import backIcon from '@imgs/back-arrow.png'

interface Params {
  id: string
  personid: string
  taskid: string
}

interface Props {
  history: History
  match: match<Params>
  userInfo: USERINFO
  location: Location
}

// 初始化案件数据
async function initData (id: string, setDetail: (v: MeetSchema) => void): Promise<void> {
  const [e, d] = await awaitWrap(getDetailRequest(id))
  if (e === null) {
    if (d !== null) {
      setDetail(d)
    } else {
      message.warning('数据不存在').then(null, null)
    }
  }
}

// 创建任务
async function createTask (meetingid: string, id: string): Promise<void> {
  const h = message.loading('加载中', 60)
  const [e, d] = await awaitWrap(createTaskRequest(id))
  h()
  if (e !== null) {
    return undefined
  }
  if (d !== null) {
    const url = resetUrlParams(d.sign_url, { callback: `${location.origin}/admin/meeting-detail/signature/${meetingid}/${id}/${d.taskid}` })
    location.replace(url)
  }
}

// 查看签署
async function getSignatureStatus (taskid: string, setLoading: (v: boolean) => void, setIsSuccess: (v: boolean) => void, setNotice: (v: string) => void): Promise<void> {
  const h = message.loading('获取签名状态', 60)
  setLoading(true)
  const [e, d] = await awaitWrap(searchTaskStatusRequest(taskid))
  setLoading(false)
  h()
  if (e === null && d !== null) {
    setIsSuccess(true)
    setNotice(d.message)
  } else {
    setNotice(e.message)
  }
}

function Main ({ history, match, userInfo, location }: Props): ReactElement {
  const [detail, setDetail] = useState<MeetSchema | null>(null)
  const [id, setId] = useState('')
  const [personid, setPersonid] = useState('')
  const [taskid, setTaskid] = useState('')
  const [loading, setLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [notice, setNotice] = useState('')
  const refreshCase = function (): void {
    initData(match.params.id, setDetail).catch(e => console.error(e))
  }
  useEffect(() => {
    if (match !== null) {
      refreshCase()
      setId(match.params.id)
      setPersonid(match.params.personid)
      setTaskid(match.params.taskid)
    }
  }, [])
  useEffect(() => {
    if (personid.length > 0) {
      getSignatureStatus(taskid, setLoading, setIsSuccess, setNotice).catch(e => console.error(e))
    }
  }, [personid])
  if (detail === null) {
    return (
      <div className={style.loading}><Skeleton active /></div>
    )
  }
  return (
    <div className={style.container}>
      <BerifInfo data={detail} />
      <div className={style.body}>
        <div className={style.header}>
          <span className={style['header-icon']} onClick={() => history.push('/admin/meeting-list')}>
            <img src={backIcon} />
          </span>
          <span className={style['header-title']}>
            <img className={style.logo} src={logo} />
            <span className={style['header-title-text']}>视频庭审{detail.caseno_desc}</span>
          </span>
        </div>
        <div className={style.block}>
          <div className={style['title-line']}>文书签署情况</div>
          <Skeleton loading={loading} active>
            <div className={style['sign-status-block']}>
              {isSuccess
                ? (
                <Result status="success" title={notice} extra={[<Button onClick={() => history.push(`/admin/meeting-detail/${id}`)}>返回详情</Button>]} />
                  )
                : (
                <Result status="warning" title={notice} extra={[<Button type='primary' onClick={async () => await createTask(id, personid)}>重新确认签署笔录</Button>]} />
                  )}
            </div>
          </Skeleton>
        </div>
      </div>
      <BackTop>
        <span className={style.backtop}><ToTopOutlined /></span>
      </BackTop>
    </div>
  )
}

export default connect((state: { basic: BASIC_STATE }) => {
  return {
    userInfo: state.basic.userInfo
  }
}, dispatch => {
  return {

  }
})(Main)
