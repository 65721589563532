import React from 'react'
import { Checkbox } from 'antd'
import style from './Agree.module.less'
import { companyName } from '@js/config'

interface Props {
  value: boolean
  dispatch: (o: any) => void
}

function Main ({ value, dispatch }: Props): React.ReactElement {
  return (
    <div className={style.container}>
      <Checkbox checked={value} onChange={e => dispatch({ type: 'agree', data: e.target.checked })} className={style.checkbox} />
      <span onClick={() => dispatch({ type: 'agree', data: !value })}>
        <span>我同意{companyName}使用我所提交的手机号用于快捷注册/登录。查看</span>
        <a className={style.link} href='http://service.wuhuzcw.cn/doc/agreement.pdf' target='blank' onClick={e => e.stopPropagation()}>《用户服务协议》</a>
        <span>及</span>
        <a className={style.link} href='http://service.wuhuzcw.cn/doc/privacy.pdf' target='blank' onClick={e => e.stopPropagation()}>《隐私政策》</a>
      </span>
    </div>
  )
}

export default Main
