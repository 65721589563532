import { ColumnType } from 'antd/lib/table'

export function getColumn (): Array<ColumnType<any>> {
  const columns = [
    {
      title: '序号',
      dataIndex: 'no',
      key: 'no',
      width: '80px'
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '性别',
      dataIndex: 'gender',
      key: 'gender'
    },
    {
      title: '专业',
      dataIndex: 'special',
      key: 'special'
    },
    {
      title: '职业',
      dataIndex: 'occupation_out',
      key: 'occupation_out'
    },
    {
      title: '所在地区',
      dataIndex: 'area',
      key: 'area'
    }
  ]
  return columns
}
