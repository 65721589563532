import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import 'core-js/es'
import 'mutation-observer'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/lib/locale/zh_CN'
import Router from '@router/index'
import store, { persistor } from '@redux/index'
import reportWebVitals from './reportWebVitals'
// import TestWaterMark from './components/TestWaterMark/Index'
import './reset.less'
import 'antd/dist/antd.less'
import './index.less'

ReactDOM.render(
  <ConfigProvider locale={zhCN}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router />
        {/* <TestWaterMark size={3} /> */}
      </PersistGate>
    </Provider>
  </ConfigProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
