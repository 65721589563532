import { ReactElement, useState, useEffect } from 'react'
import classNames from 'classnames'
import { Table } from 'antd'
import style from './Index.module.less'
import { getColumn } from './util'
import { FileSchema } from '@src/components/Schema/FileSchema'
import { awaitWrap } from '@src/assets/js/tool'
import { getTemplateRequest } from './ajax'

async function initList (setLoading: (v: boolean) => void, setList: (v: FileSchema[]) => void): Promise<void> {
  setLoading(true)
  const [e, d] = await awaitWrap(getTemplateRequest())
  setLoading(false)
  if (e === null && d !== null) {
    setList(d)
  }
}

function Main (): ReactElement {
  const [isFixed, setIsFixed] = useState(window.document.documentElement.offsetWidth >= 1280)
  const [list, setList] = useState<FileSchema[]>([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    function initFixed (): void {
      setIsFixed(window.document.documentElement.offsetWidth >= 1280)
    }
    window.addEventListener('resize', initFixed)
    return () => {
      window.removeEventListener('resize', initFixed)
    }
  }, [])
  useEffect(() => {
    initList(setLoading, setList).then(null, null)
  }, [])
  return (
    <div className={style.container}>
      <div className={classNames(style['left-block'], { [style.fixed]: isFixed })}>
        <div className={style['page-title']}>文书下载</div>
      </div>
      <div className={style.body}>
        <Table rowKey='name' columns={getColumn()} dataSource={list} pagination={false} loading={loading} />
      </div>
    </div>
  )
}

export default Main
