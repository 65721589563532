import React, { ReactElement, useEffect, useState } from 'react'
import { match, Switch, Route, Redirect } from 'react-router-dom'
import { Location, History } from 'history'
import { getCaseDetailRequest, updateCaseRequest, SubData } from './ajax'
import { connect } from 'react-redux'
import { awaitWrap } from '@js/tool'
import { message, Skeleton } from 'antd'
import { CaseSchema } from '@components/Schema/CaseSchema'
import style from './Index.module.less'
import TotalInfo from './pages/TotalInfo/Index'
import PartyEdit from './pages/PartyEdit/Index'
import AgentEdit from './pages/AgentEdit/Index'
import Accord from './pages/Accord/Index'
import ApplyDoc from './pages/ApplyDoc/Index'
import CauseAction from './pages/CauseAction/Index'
import Statement from './pages/Statement/Index'
import Claim from './pages/Claim/Index'
import AddClaim from './pages/AddClaim/Index'
import Evidence from './pages/Evidence/Index'
import Dictionary from '@js/dictionary'
import { USERINFO } from '@src/redux/actions/basic'
import { BASIC_STATE } from '@src/redux/reducers/basic'

interface Params {
  caseid: string
  type?: string
  id?: string
}

interface Props {
  match: match<Params>
  location: Location
  userInfo: USERINFO
  history: History
}

// 获取案件详情
async function getCaseDetail (caseid: string, h: History, setCaseDetail: (v: CaseSchema) => void): Promise<void> {
  const hide = message.loading('加载中')
  const [e, d] = await awaitWrap(getCaseDetailRequest(caseid))
  hide()
  if (e !== null) {
    await undefined
  } else if (d === null) {
    await message.error('案件数据未返回')
  } else if (!['未提交', '审核不通过', null, undefined, ''].includes(d.status)) {
    h.push('/admin/case-list')
    await message.warning('案件非可编辑状态')
  } else {
    setCaseDetail(d)
  }
}

// 设置字典
async function initDictionary (setDictionary: (v: Dictionary) => void): Promise<void> {
  const d = new Dictionary()
  await d.init('案件大类,涉外情况,仲裁程序,案件来源,行为收费,主体类型,自然人证件类型,企业证件类型,所在地,特殊代理权限,当事人选择送达地址类型,送达地址类型')
  setDictionary(d)
}

// 更新案件数据
async function updateCaseDetail (caseDetail: CaseSchema, modifyData: SubData, userInfo: USERINFO, history: History, setCaseDetail: (v: CaseSchema) => void): Promise<void> {
  const hide = message.loading('提交中', 120)
  const [e] = await awaitWrap(updateCaseRequest(caseDetail, modifyData, userInfo))
  hide()
  if (e !== null) {
    return undefined
  }
  history.push(`/admin/case-edit/${caseDetail.id}`)
  await getCaseDetail(caseDetail.id, history, setCaseDetail)
}

function Main ({ match, location, userInfo, history }: Props): ReactElement {
  const [caseid, setCaseid] = useState('')
  const [caseDetail, setCaseDetail] = useState<CaseSchema | null>(null)
  const [dictionary, setDictionary] = useState<Dictionary | null>(null)
  useEffect(() => {
    if (match !== null && caseid !== match.params.caseid) {
      setCaseid(match.params.caseid)
    }
  }, [match])
  useEffect(() => {
    if (caseid !== '' && caseid !== undefined) {
      getCaseDetail(caseid, history, setCaseDetail).catch(e => console.error(e))
    }
  }, [caseid])
  useEffect(() => {
    initDictionary(setDictionary).catch(e => console.error(e))
  }, [])
  if (caseDetail === null || dictionary === null) {
    return (
      <div className={style.loading}>
        <Skeleton active />
      </div>
    )
  }
  return (
    <div className={style.container}>
      <Switch location={location}>
        <Route exact path='/admin/case-edit/:caseid' render={({ history, match }) => <TotalInfo history={history} match={match} caseDetail={caseDetail} />} />
        <Route exact path='/admin/case-edit/:caseid/party/:type/:id' render={({ history, match }) => <PartyEdit history={history} match={match} caseDetail={caseDetail} dictionary={dictionary} refreshCase={async () => await getCaseDetail(caseid, history, setCaseDetail)} />} />
        <Route exact path='/admin/case-edit/:caseid/party/:type' render={({ history, match }) => <PartyEdit history={history} match={match} caseDetail={caseDetail} dictionary={dictionary} refreshCase={async () => await getCaseDetail(caseid, history, setCaseDetail)} />} />
        <Route exact path='/admin/case-edit/:caseid/agent/:type/:id' render={({ history, match }) => <AgentEdit history={history} match={match} caseDetail={caseDetail} dictionary={dictionary} refreshCase={async () => await getCaseDetail(caseid, history, setCaseDetail)} />} />
        <Route exact path='/admin/case-edit/:caseid/agent/:type' render={({ history, match }) => <AgentEdit history={history} match={match} caseDetail={caseDetail} dictionary={dictionary} refreshCase={async () => await getCaseDetail(caseid, history, setCaseDetail)} />} />
        <Route exact path='/admin/case-edit/:caseid/accord' render={({ history, match }) => <Accord history={history} match={match} caseDetail={caseDetail} dictionary={dictionary} onChange={async d => await updateCaseDetail(caseDetail, d, userInfo, history, setCaseDetail)} />} />
        <Route exact path='/admin/case-edit/:caseid/apply-doc' render={({ history, match }) => <ApplyDoc history={history} match={match} caseDetail={caseDetail} dictionary={dictionary} onChange={async d => await updateCaseDetail(caseDetail, d, userInfo, history, setCaseDetail)} />} />
        <Route exact path='/admin/case-edit/:caseid/cause-action' render={({ history, match }) => <CauseAction history={history} match={match} caseDetail={caseDetail} dictionary={dictionary} onChange={async d => await updateCaseDetail(caseDetail, d, userInfo, history, setCaseDetail)} />} />
        <Route exact path='/admin/case-edit/:caseid/statement' render={({ history, match }) => <Statement history={history} match={match} caseDetail={caseDetail} dictionary={dictionary} onChange={async d => await updateCaseDetail(caseDetail, d, userInfo, history, setCaseDetail)} />} />
        <Route exact path='/admin/case-edit/:caseid/claim' render={({ history, match }) => <AddClaim history={history} match={match} caseDetail={caseDetail} dictionary={dictionary} onChange={async d => await updateCaseDetail(caseDetail, d, userInfo, history, setCaseDetail)} />} />
        <Route exact path='/admin/case-edit/:caseid/claim/:id' render={({ history, match }) => <Claim history={history} match={match} caseDetail={caseDetail} dictionary={dictionary} onChange={async d => await updateCaseDetail(caseDetail, d, userInfo, history, setCaseDetail)} />} />
        <Route exact path='/admin/case-edit/:caseid/evidence/:id' render={({ history, match }) => <Evidence history={history} match={match} caseDetail={caseDetail} dictionary={dictionary} refreshCase={async () => await getCaseDetail(caseid, history, setCaseDetail)} />} />
        <Route exact path='/admin/case-edit/:caseid/evidence' render={({ history, match }) => <Evidence history={history} match={match} caseDetail={caseDetail} dictionary={dictionary} refreshCase={async () => await getCaseDetail(caseid, history, setCaseDetail)} />} />
        <Route path='/admin/case-edit/:caseid/*' render={({ history, match }) => <Redirect to={`/admin/case-edit/${match.params.caseid}`} />} />
      </Switch>
    </div>
  )
}

export default connect((state: { basic: BASIC_STATE }) => {
  return {
    userInfo: state.basic.userInfo
  }
}, dispatch => {
  return {

  }
})(Main)
