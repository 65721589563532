import React, { ReactElement } from 'react'
import style from './Index.module.less'
import { FileSchema } from '../Schema/FileSchema'

function Main ({ value }: { value: FileSchema[] }): ReactElement {
  if (!(value instanceof Array)) {
    return <span />
  }
  return (
    <span className={style.links}>
      {
        value.map((li, i) => (
          <span key={i} className={style.link}>
            <span>{i + 1}、</span>
            <a href={li.path} target='_blank' rel='noopener noreferrer'>{li.original_filename}</a>
            <span>；</span>
          </span>
        ))
      }
    </span>
  )
}

export default Main
