
// 普通受理费计算
function defaultFeeAcceptace (amount: number): number {
  if (amount <= 1000) {
    return 40
  } else if (amount > 1000 && amount <= 50000) {
    return 40 + (amount - 1000) * 0.04
  } else if (amount > 50000 && amount <= 100000) {
    return 2000 + (amount - 50000) * 0.03
  } else if (amount > 100000 && amount <= 200000) {
    return 3500 + (amount - 100000) * 0.02
  } else if (amount > 200000 && amount <= 500000) {
    return 5500 + (amount - 200000) * 0.01
  } else if (amount > 500000 && amount <= 1000000) {
    return 8500 + (amount - 500000) * 0.005
  } else if (amount > 1000000) {
    return 11000 + (amount - 1000000) * 0.0025
  }
  return 40
}

// 普通处理费计算
function defaultFeeProcessing (acceptFee: number): number {
  const f = 0.2 * acceptFee
  if (f < 200) {
    return 200
  }
  return f
}

// 普通收费计算
export function defaultFeeCompute (amount: number): {
  fee_acceptance: number
  fee_processing: number
  fee: number
} {
  const acceptance = Math.round(defaultFeeAcceptace(amount))
  const processing = Math.round(defaultFeeProcessing(acceptance))
  return {
    fee_acceptance: acceptance,
    fee_processing: processing,
    fee: acceptance + processing
  }
}
