import { FileSchema } from '@src/components/Schema/FileSchema'
import { ColumnType } from 'antd/lib/table'

export function getColumn (): Array<ColumnType<any>> {
  const columns = [
    {
      title: '序号',
      dataIndex: 'orderno',
      key: 'orderno',
      width: '80px'
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
      render: (v: string, l: FileSchema) => {
        return <a href={l.url} download={v} target='_blank'>{v}</a>
      }
    }
  ]
  return columns
}
