import React, { ReactElement, useState, useEffect } from 'react'
import { match } from 'react-router-dom'
import { History, Location } from 'history'
import { Skeleton, BackTop, message } from 'antd'
import { ToTopOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { USERINFO } from '@src/redux/actions/basic'
import { BASIC_STATE } from '@src/redux/reducers/basic'
import style from './Index.module.less'
import BerifInfo from './components/BerifInfo/Index'
import logo from '@imgs/logo.png'
import { MeetSchema, MeetingPersonSchema } from '@components/Schema/MeetSchema'
import { getDetailRequest } from './ajax'
import { awaitWrap, formatDate } from '@js/tool'
import backIcon from '@imgs/back-arrow.png'
import classNames from 'classnames'
import moment from 'moment'
import DocBlock from './components/DocBlock/Index'

interface Params {
  id: string
}

interface Props {
  history: History
  match: match<Params>
  userInfo: USERINFO
  location: Location
}

// 初始化案件数据
async function initData (id: string, setDetail: (v: MeetSchema) => void): Promise<void> {
  const [e, d] = await awaitWrap(getDetailRequest(id))
  if (e === null) {
    if (d !== null) {
      setDetail(d)
    } else {
      message.warning('数据不存在').then(null, null)
    }
  }
}

function getTime (start: number, end: number): string {
  const year = moment(start * 1000).format('YYYY-MM-DD')
  const st = moment(start * 1000).format('HH:mm')
  const et = moment(end * 1000).format('HH:mm')
  return `${year} ${st} ~ ${et}`
}

function Main ({ history, match, userInfo, location }: Props): ReactElement {
  const [detail, setDetail] = useState<MeetSchema | null>(null)
  const [time, setTime] = useState('')
  const [personList, setPersonList] = useState<MeetingPersonSchema[]>([])
  const [person, setPerson] = useState<MeetingPersonSchema | null>(null)
  const [meetingid, setMeetingid] = useState('')
  const refreshCase = function (): void {
    initData(match.params.id, setDetail).catch(e => console.error(e))
  }
  useEffect(() => {
    if (detail !== null) {
      setPersonList(detail.participant)
    }
  }, [detail])
  useEffect(() => {
    if (personList instanceof Array) {
      for (let i = 0; i < personList.length; i++) {
        const p = personList[i]
        if (p.accountid === userInfo.id) {
          setPerson(p)
          break
        }
      }
    }
  }, [personList])
  useEffect(() => {
    if (match !== null) {
      refreshCase()
      setMeetingid(match.params.id)
    }
  }, [])
  useEffect(() => {
    if (detail !== null) {
      setTime(getTime(detail.begin_datetime, detail.end_datetime))
    }
  }, [detail])
  if (detail === null) {
    return (
      <div className={style.loading}><Skeleton active /></div>
    )
  }
  return (
    <div className={style.container}>
      <BerifInfo data={detail} />
      <div className={style.body}>
        <div className={style.header}>
          <span className={style['header-icon']} onClick={() => history.push('/admin/meeting-list')}>
            <img src={backIcon} />
          </span>
          <span className={style['header-title']}>
            <img className={style.logo} src={logo} />
            <span className={style['header-title-text']}>视频庭审{detail.caseno_desc}</span>
          </span>
        </div>
        <div className={style.block}>
          <div className={style['title-line']}>庭室基本信息</div>
          <div className={classNames(style['value-line'], style.warning)}>开庭时间：{time}</div>
          <div className={classNames(style['value-line'], style.warning)}>开庭状态：{detail.status}</div>
          <div className={style['value-line']}>创建时间：{formatDate(detail.create_datetime, 'YYYY-MM-DD HH:mm')}</div>
        </div>
        <div className={style.block}>
          <div className={style['title-line']}>开庭人员</div>
          {
            personList.map((li, i) => {
              return (
                <div className={style['value-line']} key={i}>
                  <span className={style['order-no']}>{i + 1}</span>
                  <span>{li.type}：{li.name}</span>
                </div>
              )
            })
          }
        </div>
        <DocBlock data={detail} person={person} personList={personList} meetingid={meetingid} />
      </div>
      <BackTop>
        <span className={style.backtop}><ToTopOutlined /></span>
      </BackTop>
    </div>
  )
}

export default connect((state: { basic: BASIC_STATE }) => {
  return {
    userInfo: state.basic.userInfo
  }
}, dispatch => {
  return {

  }
})(Main)
