import React, { ReactElement } from 'react'
import { Button, message, Space } from 'antd'
import { History } from 'history'
import { match } from 'react-router-dom'
import { CaseSchema } from '@components/Schema/CaseSchema'
import style from './Index.module.less'
import logo from '@imgs/logo.png'
import Party from './components/Party/Index'
import Agent from './components/Agent/Index'
import Accord from './components/Accord/Index'
import ApplyDoc from './components/ApplyDoc/Index'
import CauseAction from './components/CauseAction/Index'
import Claim from './components/Claim/Index'
import Statement from './components/Statement/Index'
import Evidence from './components/Evidence/Index'
import { validateCase } from './tool'
import { awaitWrap, confirmAction } from '@js/tool'
import { USERINFO } from '@src/redux/actions/basic'
import { updateCaseRequest } from '../../../CaseDetail/ajax'
import { useSelector } from 'react-redux'
import { BASIC_STATE } from '@redux/reducers/basic'

interface Params {
  caseid: string
}

interface Props {
  history: History
  match: match<Params>
  caseDetail: CaseSchema
}

// 删除案件
async function delHandle (d: CaseSchema, h: History, u: USERINFO): Promise<void> {
  const hide = message.loading('提交中', 120)
  const [e] = await awaitWrap(updateCaseRequest(d, { status: '待审核' }, u))
  hide()
  if (e !== null) {
    return undefined
  }
  message.success('删除成功', 1).then(() => {
    h.push('/admin/case-list')
  }, null)
}

// 前往预览
function previewHandle (caseDetail: CaseSchema, history: History): void {
  if (validateCase(caseDetail)) {
    history.push(`/admin/preview/${caseDetail.id}`)
  }
}

function Main ({ caseDetail, history, match }: Props): ReactElement {
  const userInfo = useSelector((state: { basic: BASIC_STATE }) => state.basic.userInfo)
  return (
    <div className={style.container}>
      <div className={style['title-line']}>
        <img src={logo} className={style.logo} />
        <span className={style.title}>仲裁申请书</span>
      </div>
      <Party caseDetail={caseDetail} type='applicant' history={history} />
      <Agent caseDetail={caseDetail} type='applicant' history={history} />
      <Party caseDetail={caseDetail} type='respondent' history={history} />
      <Accord caseDetail={caseDetail} history={history} />
      <ApplyDoc caseDetail={caseDetail} history={history} />
      <Claim caseDetail={caseDetail} history={history} />
      <Statement caseDetail={caseDetail} history={history} />
      <CauseAction caseDetail={caseDetail} history={history} />
      <Evidence caseDetail={caseDetail} history={history} />
      <div className={style['btn-line']}>
        <div className={style['btn-box']}>
          <Space size='middle'>
            <Button onClick={() => confirmAction('您确定要删除案件吗？', () => { delHandle(caseDetail, history, userInfo).catch(e => console.error(e)) })}>删除案件</Button>
            <Button type='primary' onClick={() => previewHandle(caseDetail, history)}>预览提交</Button>
          </Space>
        </div>
      </div>
    </div>
  )
}

export default Main
