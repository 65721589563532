import React, { ReactElement, useState, RefObject } from 'react'
import { AutoComplete, message, Input } from 'antd'
import { FormInstance } from 'antd/lib/form'
import _ from 'lodash'
import { getPersonListRequest, Info, getPersonDataRequest } from './ajax'
import { awaitWrap } from '@js/tool'

interface Props {
  identityType: string
  nameLabel: string
  className: string
  formRef: RefObject<FormInstance>
  isSelf: boolean
  value?: string
  onChange?: any
}

// 获取匹配列表
async function getPersonList (name: string, setInfoList: (v: Info[]) => void, setLoading: (v: boolean) => void): Promise<void> {
  setLoading(true)
  const [e, d] = await awaitWrap(getPersonListRequest(name))
  setLoading(false)
  if (e !== null) {
    await undefined
  } else if (d === null) {
    await message.error('数据未返回')
  } else {
    setInfoList(d)
  }
}

const fn = _.debounce(getPersonList, 1000)

// 改变名称，触发搜索
async function changeName (name: string, formRef: RefObject<FormInstance>, setInfoList: (v: Info[]) => void, setLoading: (v: boolean) => void): Promise<void> {
  const c = formRef.current
  if (c === null) {
    return undefined
  }
  c.setFieldsValue({
    name
  })
  if (!isEmpty(name) && fn !== undefined) {
    await fn(name, setInfoList, setLoading)
  }
}

// 过滤空
function isEmpty (s: string): boolean {
  return s === null || s === undefined || s === ''
}

// 选择当事人
async function selectPersonInfo (name: string, formRef: RefObject<FormInstance>): Promise<void> {
  const c = formRef.current
  const [e, d] = await awaitWrap(getPersonDataRequest(name))
  if (e !== null) {
    await undefined
  } else if (d === null) {
    await message.error('数据未返回')
  } else {
    formatData(d)
    delete d.id
    if (c !== null) {
      c.setFieldsValue(d)
    }
  }
}

// 格式化数据标签
function formatData (d: Info): void {
  if (!isEmpty(d.tag)) {
    try {
      const o: { subject_type_tag?: string } = JSON.parse(d.tag)
      if (o.subject_type_tag !== undefined) {
        d.subject_type_tag = o.subject_type_tag
      }
    } catch (e) {
      console.error(e)
    }
  }
}

function Main ({ identityType, nameLabel, className, formRef, isSelf, value, onChange }: Props): ReactElement {
  const [loading, setLoading] = useState(false)
  const [infoList, setInfoList] = useState<Info[]>([])
  if (identityType === '企业') {
    const notice = <div>{loading ? '检索中' : '无匹配数据'}</div>
    return (
      <AutoComplete className={className} placeholder={`请输入${nameLabel}`} onSearch={async e => await changeName(e, formRef, setInfoList, setLoading)} notFoundContent={notice} disabled={isSelf}>
        {infoList.map((li, i) => {
          const value = `${li.name}（证件号：${isEmpty(li.license) ? '无' : li.license}）`
          return (
            <AutoComplete.Option key={i} value={li.name}>
              <div onClick={async () => await selectPersonInfo(li.name, formRef)}>{value}</div>
            </AutoComplete.Option>
          )
        })}
      </AutoComplete>
    )
  }
  return (
    <Input className={className} placeholder={`请输入${nameLabel}`} disabled={isSelf} value={value} onChange={onChange} />
  )
}

export default Main
