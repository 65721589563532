import React, { ReactElement, useState, useEffect } from 'react'
import { ClockCircleOutlined, CaretRightOutlined } from '@ant-design/icons'
import moment from 'moment'
import { History } from 'history'
import style from './Index.module.less'
import { CaseSchema } from '@components/Schema/CaseSchema'
import personImg from '@imgs/case-person-icon.png'

interface ItemProps {
  data: CaseSchema
  history: History
}

interface Props {
  data: CaseSchema
}

// 值是否存在
function isExist (v: any): boolean {
  return v !== null && v !== undefined
}

// 渲染提交时间
function SubmitTime ({ t }: { t: number }): ReactElement {
  if (typeof t !== 'number') {
    return <span />
  }
  return (
    <div className={style['item-body__right']}>
      <span>申请时间</span>
      <span>{moment(t * 1000).format('YYYY-MM-DD')}</span>
    </div>
  )
}

// 获取案件状态和样式
function getStatusAndClassNames (s: string): { status: string, name: string } {
  const name = [style['item-status']]
  if (s === '待审核') {
    name.push(style.pass)
  } else if (s === '已受理') {
    name.push(style.wait)
  } else if (['送达中', '审理中'].includes(s)) {
    name.push(style.wait)
  } else if (['已结案', '不予受理'].includes(s)) {
    name.push(style.close)
  } else if (s === '审核不通过') {
    name.push(style.unpass)
  } else {
    name.push(style.unsubmit)
  }
  return {
    status: s !== '' && s !== null ? s : '未提交',
    name: name.join(' ')
  }
}

// 渲染案号
function CaseNo ({ s }: { s: string }): ReactElement {
  if (!isExist(s)) {
    return <span />
  }
  return <span className={style['item-caseno']}>案号 {s}</span>
}

// 渲染其他信息
function OtherInfo ({ data: d }: Props): ReactElement {
  if (!isExist(d.processer) && !isExist(d.arbitratorids) && !isExist(d.hearing_datetime)) {
    return <span />
  }
  return (
    <div className={style['item-other']}>
      <Scretary data={d} />
      <Arbitrator data={d} />
      <Court data={d} />
    </div>
  )
}

// 渲染秘书信息
function Scretary ({ data: d }: Props): ReactElement {
  if (!isExist(d.processer)) {
    return <span />
  }
  return (
    <div className={style['item-other-line']}>
      <span className={style['item-other-line-cell']}>办案秘书：{d.processer}</span>
      <span className={style['item-other-line-cell']}>电话：{isExist(d.processer_tel) ? d.processer_tel : '无'}</span>
      <span className={style['item-other-line-cell']}>邮箱：{isExist(d.processer_email) ? d.processer_email : '无'}</span>
    </div>
  )
}

// 渲染仲裁员信息
function Arbitrator ({ data: d }: Props): ReactElement {
  if (!isExist(d.arbitratorids)) {
    return <span />
  }
  return <div className={style['item-other-line']}>仲裁员：{d.arbitrators}</div>
}

// 渲染开庭信息
function Court ({ data: d }: Props): ReactElement {
  if (!isExist(d.hearing_datetime)) {
    return <span />
  }
  return (
    <div className={style['item-other-line']}>开庭日期：{moment(d.hearing_datetime * 1000).format('YYYY-MM-DD HH:mm')}</div>
  )
}

// 前往详情
function openDetail (d: CaseSchema, history: History): void {
  const s = d.status
  if (['未提交', '审核不通过', null, undefined, ''].includes(s)) {
    history.push(`/admin/case-edit/${d.id}`)
  } else {
    history.push(`/admin/case-detail/${d.id}`)
  }
}

function Main ({ data: d, history }: ItemProps): ReactElement {
  const [name, setName] = useState(style['item-status'])
  const [status, setStatus] = useState(d.status)
  useEffect(() => {
    const o = getStatusAndClassNames(d.status)
    setName(o.name)
    setStatus(o.status)
  }, [d])
  return (
    <div className={style.item} key={d.id}>
      <div className={style['item-no']}>{d.order}</div>
      <div className={style['item-header']} onClick={() => openDetail(d, history)}>
        <div className={style['item-header__left']}>
          <span className={style['item-caseno']}>预立案编号{d.serial_no !== '' ? d.serial_no : '无'}</span>
          <CaseNo s={d.caseno_desc} />
          <span className={name}>
            <ClockCircleOutlined className={style['item-status-icon']} />
            <span>{status}</span>
          </span>
          {d.status === '待审核' && <span className={style.notice}>请将书面材料邮寄至本会立案室</span>}
        </div>
        <div className={style['item-header__right']}>
          <CaretRightOutlined />
        </div>
      </div>
      <OtherInfo data={d} />
      <div className={style['item-body']}>
        <div className={style['item-body__left']}>
          <div className={style['item-persno-list']}>
            <div className={style['item-person__title']}>
              <img className={style['item-person-icon']} src={personImg} />
              <span>申请人</span>
            </div>
            <div className={style['item-person-names']}>
              {d.applicants.map((li, i) => <span key={i} title={li.name}>{i + 1}、{li.name}</span>)}
            </div>
          </div>
          <div className={style['item-persno-list']}>
            <div className={style['item-person__title']}>
              <img className={style['item-person-icon']} src={personImg} />
              <span>被申请人</span>
            </div>
            <div className={style['item-person-names']}>
              {d.respondents.map((li, i) => <span key={li.id} title={li.name}>{i + 1}、{li.name}</span>)}
            </div>
          </div>
        </div>
        <SubmitTime t={d.submit_datetime} />
      </div>
    </div>
  )
}

export default Main
