import { ReactElement, useState, useEffect } from 'react'
import classNames from 'classnames'
import { Table, Form, Input, Row, Col, Select, Button } from 'antd'
import style from './Index.module.less'
import { getColumn } from './util'
import { awaitWrap } from '@src/assets/js/tool'
import { getCaseList } from './ajax'
import { ArbitratorSchema } from '@src/components/Schema/ArbitratorSchema'
import Dictionary, { DictionaryItem } from '@src/assets/js/dictionary'
import { SearchOutlined } from '@ant-design/icons'
import { ConditionSchema } from '@components/Schema/ConditionSchema'

const { useForm, Item } = Form
const { Option } = Select

async function initList (condition: ConditionSchema[], current: number, pageSize: number, setLoading: (v: boolean) => void, setList: (v: ArbitratorSchema[]) => void, setTotal: (v: number) => void): Promise<void> {
  setLoading(true)
  const [e, d] = await awaitWrap(getCaseList(current, pageSize, condition))
  setLoading(false)
  if (e === null && d !== null) {
    setList(d.list.map((li, i) => {
      li.no = (pageSize * (current - 1)) + i + 1
      return li
    }))
    setTotal(d.total)
  }
}

function formatCondition (d: {[k: string]: any}, setCondition: (v: ConditionSchema[]) => void): void {
  const c: ConditionSchema[] = []
  if (typeof d.name === 'string' && d.name !== '') {
    c.push({ k: 'name', o: 'like', v: `%${d.name}%` })
  }
  if (typeof d.special === 'string' && d.special !== '') {
    c.push({ k: 'special', o: 'like', v: `%${d.special}%` })
  }
  if (d.occupation_out instanceof Array && d.occupation_out.length > 0) {
    c.push({ k: 'occupation_out', o: 'in', v: d.occupation_out.join() })
  }
  if (d.gender instanceof Array && d.gender.length > 0) {
    c.push({ k: 'gender', o: 'in', v: d.gender.join() })
  }
  if (typeof d.area === 'string' && d.area !== '') {
    c.push({ k: 'area', o: 'like', v: `%${d.area}%` })
  }
  setCondition(c)
}

function Main (): ReactElement {
  const [formRef] = useForm()
  const [isFixed, setIsFixed] = useState(window.document.documentElement.offsetWidth >= 1280)
  const [list, setList] = useState<ArbitratorSchema[]>([])
  const [loading, setLoading] = useState(false)
  const [current, setCurrent] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [specialList, setSpecialList] = useState<DictionaryItem[]>([])
  const [proList, setProList] = useState<DictionaryItem[]>([])
  const [condition, setCondition] = useState<ConditionSchema[]>([])
  useEffect(() => {
    const d = new Dictionary()
    d.init('仲裁员职业分类,专业')
      .then(() => {
        setSpecialList(d.getList('仲裁员职业分类'))
        setProList(d.getList('专业'))
      }).catch(() => {})
  }, [])
  useEffect(() => {
    function initFixed (): void {
      setIsFixed(window.document.documentElement.offsetWidth >= 1280)
    }
    window.addEventListener('resize', initFixed)
    return () => {
      window.removeEventListener('resize', initFixed)
    }
  }, [])
  useEffect(() => {
    initList(condition, current, pageSize, setLoading, setList, setTotal).then(null, null)
  }, [current, pageSize, condition])
  return (
    <div className={style.container}>
      <div className={classNames(style['left-block'], { [style.fixed]: isFixed })}>
        <div className={style['page-title']}>仲裁员名册</div>
      </div>
      <div className={style.body}>
        <div>
          <Form form={formRef} onFinish={d => {
            formatCondition(d, setCondition)
            setCurrent(1)
          }}>
            <Row gutter={24}>
              <Col span={8}>
                <Item label='姓名' name='name'>
                  <Input placeholder='请输入' allowClear />
                </Item>
              </Col>
              <Col span={8}>
                <Item label='专业' name='special'>
                  <Select placeholder='请选择' allowClear>
                    {specialList.map((li, i) => {
                      return <Option value={li.value} key={i}>{li.title}</Option>
                    })}
                  </Select>
                </Item>
              </Col>
              <Col span={8}>
                <Item label='职业' name='occupation_out'>
                  <Select placeholder='请选择' mode='multiple' allowClear>
                    {proList.map((li, i) => {
                      return <Option value={li.value} key={i}>{li.title}</Option>
                    })}
                  </Select>
                </Item>
              </Col>
              <Col span={8}>
                <Item label='性别' name='gender'>
                  <Select placeholder='请选择' allowClear>
                    <Option value='男'>男</Option>
                    <Option value='女'>女</Option>
                  </Select>
                </Item>
              </Col>
              <Col span={8}>
                <Item label='地区' name='area'>
                  <Input placeholder='请输入' allowClear />
                </Item>
              </Col>
              <Col span={8}>
                <Button htmlType='submit' type='primary' icon={<SearchOutlined />}>查询</Button>
              </Col>
            </Row>
          </Form>
        </div>
        <Table rowKey='id' columns={getColumn()} dataSource={list} pagination={{ current, pageSize, total }} loading={loading} onChange={({ pageSize, current }) => { setCurrent(current as number); setPageSize(pageSize as number) }} />
      </div>
    </div>
  )
}

export default Main
