import { $freePost, $get } from '@js/request'
import { USERINFO } from '@redux/actions/basic'

interface LoginRes {
  token: string
  userInfo: USERINFO
}

// 发送短信请求
export async function sendSMSRequest (mobile: string): Promise<string> {
  const res = await $freePost('/api/common/sms', {
    mobile,
    template_code: 'SMS_182920835'
  })
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}

// 通过手机号和验证码登录
export async function loginByMobileRequest (zoneNum: string, mobile: string, verification: string): Promise<LoginRes> {
  const res = await $freePost('/api/common/mobile_login', {
    zoneNum: zoneNum.trim(),
    mobile: mobile.trim(),
    verification: verification.trim()
  })
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}

// 通过账号密码登录
export async function loginByUsernameRequest (username: string, password: string): Promise<LoginRes> {
  const res = await $freePost('/api/common/user_login', {
    username: username.trim(),
    password: password.trim()
  })
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}

// 获取案件列表
export async function getCaseListRequest (): Promise<number> {
  const res = await $get('/api/xd/case/findCaseByAccountid', {
    params: {
      current: 1,
      page_size: 1000
    }
  })
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data.total
}
