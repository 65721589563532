import { ReactElement } from 'react'
import style from './Index.module.less'

interface Props {
  value?: string
  format?: (v: string | undefined) => string
  className?: string
}

function Main ({ value, format, className }: Props): ReactElement {
  const names: string[] = [style.container]
  if (typeof className === 'string') {
    names.push(className)
  }
  return (
    <span className={names.join(' ')}>{typeof format === 'function' ? format(value) : (value !== undefined && value !== null && value !== '' ? value : '无')}</span>
  )
}

export default Main
