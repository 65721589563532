import { ReactElement, useState, useEffect } from 'react'
import style from '../../Index.module.less'
import { MeetSchema, MeetingPersonSchema } from '@components/Schema/MeetSchema'
import FileList from '@components/FileList/Index'
import { Button, message } from 'antd'
import { createTaskRequest } from './ajax'
import { awaitWrap, resetUrlParams } from '@src/assets/js/tool'
import { FileSchema } from '@components/Schema/FileSchema'

async function createTask (meetingid: string, id: string): Promise<void> {
  const h = message.loading('加载中', 60)
  const [e, d] = await awaitWrap(createTaskRequest(id))
  h()
  if (e !== null) {
    return undefined
  }
  if (d !== null) {
    const url = resetUrlParams(d.sign_url, { callback: `${location.origin}/admin/meeting-detail/signature/${meetingid}/${id}/${d.taskid}` })
    location.replace(url)
  }
}

interface Props {
  data: MeetSchema
  person: MeetingPersonSchema | null
  personList: MeetingPersonSchema[]
  meetingid: string
}

function Main ({ data, meetingid, person, personList }: Props): ReactElement {
  const [hasFile, setHasFile] = useState(false)
  const [hasLog, setHasLog] = useState(false)
  const [signable, setSignable] = useState(false)
  const [list, setList] = useState<MeetingPersonSchema[]>([])
  const [signedFile, setSignedFile] = useState<FileSchema[]>([])
  const [isStart, setIsStart] = useState(false)
  const sl = ['会议结束', '签名授权', '笔录签名', '完成']
  useEffect(() => {
    if (person !== null && data !== null) {
      const status = person.status
      setHasLog(status === '会议结束' && data.meeting_logfile !== null)
      setHasFile(data.meeting_fileid !== null && sl.includes(status))
      setSignable(['签名授权', '会议结束'].includes(status) && data.meeting_status !== '完成')
    }
  }, [data, person])
  useEffect(() => {
    if (data !== null && data.signed_file instanceof Array) {
      setSignedFile(data.signed_file.map(li => {
        li.original_filename = li.filename
        return li
      }))
    }
    if (data !== null) {
      setIsStart(data.status === '会议开始')
    }
  }, [data])
  useEffect(() => {
    setList(personList.filter(li => li.type !== '仲裁秘书'))
  }, [personList])
  if (isStart) {
    return (
      <div className={style['btn-line']}>
        <Button type='primary' onClick={() => window.open(data.guest_joinuri)}>进入视频庭审</Button>
      </div>
    )
  }
  if (!hasFile && !hasLog) {
    return <span />
  }
  return (
    <div>
      <div className={style.block}>
        <div className={style['title-line']}>开庭笔录</div>
        {
          signedFile.length === 0
            ? (
            <>
              <div className={style['value-line']}>请先详细查看笔录，确认无误点击【确认签署笔录】如有疑问联系办案秘书</div>
              {hasFile && <div className={style['value-line']}>笔录文件：<FileList value={data.meeting_file} /></div>}
            </>
              )
            : (
            <div className={style['value-line']}>笔录文件：<FileList value={signedFile} /></div>
              )
        }

      </div>
      {hasFile && (
        <div className={style.block}>
          {
            list.map((li, i) => {
              const signed = typeof li.signed_fileid === 'string'
              return (
                <div className={style['value-line']} key={i}>
                  <span className={style['order-no']}>{i + 1}</span>
                  <span>{li.name}：{signed ? <span className={style.signed}>已签署</span> : <span className={style.unsign}>未签署</span>}</span>
                </div>
              )
            })
          }
        </div>
      )}
      <div className={style['btn-line']}>
        {signable && <Button type='primary' onClick={async () => await createTask(meetingid, person !== null ? person.id : '')}>确认签署笔录</Button>}
      </div>
    </div>
  )
}

export default Main
