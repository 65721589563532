import React, { ReactElement, useState, useEffect } from 'react'
import style from './Index.module.less'
import { List, message, Space, Button, Row, Col, Select } from 'antd'
import classNames from 'classnames'
import _ from 'lodash'
import { History } from 'history'
import { CaseSchema } from '@components/Schema/CaseSchema'
import { getCaseListRequest } from './ajax'
import { awaitWrap } from '@js/tool'
import Item from './components/Item/Index'
import SearchInput from './components/SearchInput/Index'
import ConcatCase from './components/ConcatCase/Index'

const { Option } = Select

interface Props {
  history: History
}

// 初始化案件列表
async function initCaseList (setLoading: (v: boolean) => void, setDataSource: (v: CaseSchema[]) => void, searchText: string, status: string): Promise<void> {
  setLoading(true)
  const [e, d] = await awaitWrap(getCaseListRequest(searchText, status))
  setLoading(false)
  if (e !== null) {
    await undefined
  } else if (d === null) {
    await message.error('案件列表未返回')
  } else {
    setDataSource(d)
  }
}

const changeFn: (setLoading: (v: boolean) => void, setDataSource: (v: CaseSchema[]) => void, v: string, s: string) => void = _.debounce(initCaseList, 500)

const statusList = ['全部', '未提交', '待审核', '已受理', '送达中', '审理中', '已结案', '已归档', '不予受理', '审核不通过']

function Main ({ history }: Props): ReactElement {
  const [loading, setLoading] = useState(false)
  const [dataSource, setDataSource] = useState<CaseSchema[]>([])
  const [searchText, setSearchText] = useState('')
  const [visible, setVisible] = useState(false)
  const [isFixed, setIsFixed] = useState(window.document.documentElement.offsetWidth >= 1280)
  const [status, setStatus] = useState('全部')

  useEffect(() => {
    changeFn(setLoading, setDataSource, searchText, status)
  }, [searchText, status])
  useEffect(() => {
    function initFixed (): void {
      setIsFixed(window.document.documentElement.offsetWidth >= 1280)
    }
    window.addEventListener('resize', initFixed)
    return () => {
      window.removeEventListener('resize', initFixed)
    }
  }, [])
  return (
    <div className={style.container}>
      <ConcatCase visible={visible} hide={() => setVisible(false)} refreshHandle={() => changeFn(setLoading, setDataSource, searchText, status)} />
      <div className={classNames(style['left-block'], { [style.fixed]: isFixed })}>
        <div className={style['page-title']}>我的案件</div>
      </div>
      <div className={style.content}>
        <Row gutter={24}>
          <Col span={16}>
            <SearchInput value={searchText} onChange={v => setSearchText(v)} />
          </Col>
          <Col span={8}>
            <Select className={style['status-select']} size='large' value={status} placeholder='请选择' onChange={e => setStatus(e)}>
              {
                statusList.map(li => {
                  return <Option key={li} value={li}>{li}</Option>
                })
              }
            </Select>
          </Col>
        </Row>
        <List dataSource={dataSource} loading={loading} locale={{ emptyText: '暂无数据' }} renderItem={d => <Item data={d} history={history} />} />
      </div>
      <div className={classNames(style['right-block'], { [style.fixed]: isFixed })}>
        <div className={style['operate-btn']}>
          <Space>
            <Button type='primary' ghost onClick={() => setVisible(true)}>关联案件</Button>
          </Space>
        </div>
      </div>
    </div>
  )
}

export default Main
