import { ReactElement, useState, useEffect } from 'react'
import classNames from 'classnames'
import { Form, InputNumber } from 'antd'
import style from './Index.module.less'
import Text from '@components/Text/Index'
import { defaultFeeCompute } from '@js/fee'
import { formatMoney } from '@src/assets/js/tool'

const { Item, useForm } = Form

function Main (): ReactElement {
  const [formRef] = useForm()
  const [isFixed, setIsFixed] = useState(window.document.documentElement.offsetWidth >= 1280)
  useEffect(() => {
    function initFixed (): void {
      setIsFixed(window.document.documentElement.offsetWidth >= 1280)
    }
    window.addEventListener('resize', initFixed)
    return () => {
      window.removeEventListener('resize', initFixed)
    }
  }, [])
  useEffect(() => {
    if (formRef !== null) {
      const f = defaultFeeCompute(0)
      formRef.setFieldsValue({
        accept: f.fee_acceptance,
        process: f.fee_processing,
        total: f.fee
      })
    }
  }, [formRef])
  return (
    <div className={style.container}>
      <div className={classNames(style['left-block'], { [style.fixed]: isFixed })}>
        <div className={style['page-title']}>仲裁费计算</div>
      </div>
      <div className={style.body}>
        <div className={style['fee-block']}>
          <Form form={formRef} size='large' labelCol={{ flex: '100px' }} initialValues={{ amount: 0 }}>
            <Item name='amount' label='争议金额'>
              <InputNumber size='large' className={style.full} placeholder='请输入金额' onInput={v => {
                const n = Number(v)
                const f = defaultFeeCompute(isNaN(n) ? 0 : n)
                formRef.setFieldsValue({
                  accept: f.fee_acceptance,
                  process: f.fee_processing,
                  total: f.fee
                })
              }} />
            </Item>
            <Item name='accept' label='处理费'>
              <Text format={(v) => `${formatMoney(typeof v === 'number' ? v : 0)} 元`} />
            </Item>
            <Item name='process' label='受理费'>
              <Text format={(v) => `${formatMoney(typeof v === 'number' ? v : 0)} 元`} />
            </Item>
            <Item name='total' label='仲裁费合计'>
              <Text format={(v) => `${formatMoney(typeof v === 'number' ? v : 0)} 元`} />
            </Item>
          </Form>
        </div>
        <div className={style.article}>
          <div className={style.p}>
            <span><span className={style['article-title']}>仲裁案件受理费收取标准争议金额</span>(人民收费标收费金额(人民币))</span>
          </div>
          <div className={style.p}>
            <span>1000元及以下的部分40元</span>
          </div>
          <div className={style.p}>
            <span>1001至50000元的部分按4%交纳</span>
          </div>
          <div className={style.p}>
            <span>50001至100000元的部分按3%交纳</span>
          </div>
          <div className={style.p}>
            <span>100001至200000元的部分按2%交纳200001至500000元的部分按1%交纳</span>
          </div>
          <div className={style.p}>
            <span>500001至1000000元的部分按0.5%交纳1000001元以上的部分按0.25%交纳</span>
          </div>
          <div className={style.p}>
            <span>案件处理费=案件受理费*20%</span>
          </div>
          <div className={style.p}>
            <span>说明:案件处理费不足200元的按200元收取，仲裁费用=仲裁受理费+仲裁处理费。</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Main
