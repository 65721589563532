export const companyName = '芜湖仲裁委员会'
export const appName = '当事人'
export const companyUrl = 'service.wuhuzcw.cn'
export const version = 'v1.0'
export const pk = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDBjclmTJanis9pZ/0dp921DlYb
Fkr5zw09fuJufDp8b1RBvHuKzjexFr21YlbgP6Dfch1W3VkgW8+sbJTjMHHixTFJ
AJFr04xmaXsRfGxjoEP65701stXKiS1DJaZoYZB1SAqjG1qfdzEUPBZJeKGVsa5G
jrbw8GreadEexa6XIwIDAQAB
-----END PUBLIC KEY-----
`
